import { apispreadsheets, imgFromDriveUrl } from '../shared/ApiSpreadSheets';
import { useState } from 'react';
import { useEffect } from 'react';
import { Markup } from 'interweave';

import './AdmissionInfo.css';
function AdmissionInfo() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [sheets, setSheets] = useState([]);

  //FETCH DATA
  useEffect(() => {
    const sheetName = 'thong_tin_tuyen_sinh';
    fetch(`${apispreadsheets}/${sheetName}`)
      .then((res) => res.json())
      .then(
        (results) => {
          setIsLoaded(true);
          setSheets(results);
        },
        (error) => {
          console.log('error: ', error);
          setIsLoaded(false);
        },
      );
  }, []);

  return (
    <>
      <div className="mt-20 mb-20 md:border-t-8 md:border-solid md:border-primary-color sm:border-none">
        <div className="mt-3 flex w-full">
          <div className="lg:w-[25%] md:w-[10%] flex justify-end">
            <div className="admission-left-cirle md:block sm:hidden mt-[-43px] mr-[-28px] absolute bg-white border-[9px] border-solid border-primary-color rounded-full h-14 w-14 shadow-[0px_0px_10px_2px_rgb(0,0,0,0.13)]"></div>
          </div>
          <div className="m-5 md:mt-[-83px] md:m-0 md:w-[80%] sm:m-[2%] sm:w-full lg:w-[52%] border-2 border-primary-color rounded-[28px] shadow-[0px_3px_15px_4px_rgb(0,0,0,0.19)] border-t-0">
            <h1 className="bg-primary-color rounded-[25px] p-8 md:p-12 text-xl md:text-3xl font-bold uppercase text-center text-white">
              {isLoaded && sheets[0].tieu_de}
            </h1>
            <ul className="pt-6 pb-12 px-8 md:px-12 leading-8">
              {isLoaded && <Markup allowAttributes content={sheets[0].noi_dung} />}
            </ul>
          </div>
          <div className="lg:w-[25%] md:w-[10%] flex">
            <div className="admission-right-cirle md:block sm:hidden mt-[-43px] ml-[-28px] absolute bg-white border-[9px] border-solid border-primary-color rounded-full h-14 w-14  shadow-[0px_0px_10px_2px_rgb(0,0,0,0.13)]"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdmissionInfo;
