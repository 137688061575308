import './CustomPaging.css';

function CustomPaging({ className, onClick }) {
  return (
    <>
      <div className={`customPaging ${className}`} onClick={onClick}></div>
    </>
  );
}

export default CustomPaging;
