import React, { useState, useEffect } from 'react';

import './instructors.css';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickArrow from '../shared/SlickArrow';
import CustomPaging from '../shared/CustomPaging';

import Description from '../shared/Description';
import Title from '../shared/Title';

import instructorsAPI from '../../api/instructor';
import { imgFromDriveUrl } from '../shared/Helper';

//Slider settings
var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SlickArrow right="-12px" />,
  prevArrow: <SlickArrow left="-12px" />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Instructors = () => {
  const [instructors, setInstructors] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchInstructor = async () => {
      const res = await instructorsAPI.getAll();
      setInstructors(res);
      setTitle(res[0].title);
      setDescription(res[0].description);
    };
    fetchInstructor();
  }, []);

  return (
    <div className="mt-40 w-full relative overflow-hidden">
      <div className="relative px-5 xl:px-36 z-10">
        <Title className="text-center text-primary-color">{title}</Title>

        <Description className="mt-5 text-left font-medium">
          {description}
        </Description>

        <Slider className="mt-10 w-auto mx-5" {...settings}>
          {instructors.map((instructor, index) => (
            <div key={index} className="instr-wrapper relative min-h-fit">
              <div className="instr-img-wrapper  h-[18rem] overflow-hidden">
                <img
                  alt="Ảnh giảng viên Arena Cusc"
                  className="instr-img h-full w-auto top-0 object-cover"
                  src={imgFromDriveUrl(instructor.image)}
                />
              </div>
              <div className="text-base font-bold text-center mt-10">{instructor.name}</div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="horizontal-bar block relative h-32  -translate-y-28 z-1 w-full bg-[#fdf34e]"></div>
    </div>
  );
};

export default Instructors;
