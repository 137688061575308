import React, { useEffect, useState } from 'react';

import aptechLogo from '../../assets/images/Logo/logo_aptech.png';
import dhctLogo from '../../assets/images/Logo/logo_ctu.png';
import cuscLogo from '../../assets/images/Logo/logo_cusc.png';
import arenaLogo from '../../assets/images/Logo/logo_arena.png';

import './header.css';
import './navbar.css';

const Header = () => {
  //Custom scrollpy begin
  var sections = [];
  var isScrolling;

  // Scroll to section via link
  const scrollToSection = (link) => {
    link = link.replace('#', '');
    let position;
    try {
      if (link === 'trangchu') {
        position = 0;
      } else {
        position = document.getElementById(link).offsetTop - 90;
      }
      window.scroll({
        left: 0,
        top: position,
        behavior: 'smooth',
      });
      setOpen(false);
    } catch (error) {
      // console.log('Section does not exist');
    }
  };
  // Detect current menu item
  const observeMenu = () => {
    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling);
    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function () {
      // Run the callback
      var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
      let current;
      sections.every((item, index) => {
        if (scrollPosition < 300) {
          current = 'trangchu';
          return true;
        } else if (item.pos <= scrollPosition + 200) {
          current = item.name;
          return true;
        }
        return false;
      });
      changeActiveMenu(current);
    }, 100);
  };

  const changeActiveMenu = (link) => {
    menuItems.forEach((item, index) => {
      if (`#${link}` === item.link) {
        setActiveMenuItem(index);
      }
    });
  };

  useEffect(() => {
    var section = document.querySelectorAll('[data-scroll]');
    sections.length = 0;
    section.forEach((sec) => {
      sections.push({ name: sec.id, pos: sec.offsetTop });
    });

    window.addEventListener('scroll', observeMenu, false);
    window.addEventListener('hashchange', (e) => {
      e.stopPropagation();
      e.preventDefault();
    });
    return () => {
      window.removeEventListener('scroll', observeMenu);
      window.removeEventListener('hashchange', () => {});
    };
  }, []);
  // Custom scrolly end

  // Conditional class joining function
  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  // Navigation
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const menuItems = [
    {
      name: 'Trang chủ',
      link: '#trangchu',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
          <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
        </svg>
      ),
    },
    {
      name: 'Giới thiệu',
      link: '#gioithieu',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Ghi danh',
      link: '#ghidanh',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
          <path
            fillRule="evenodd"
            d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z"
            clipRule="evenodd"
          />
          <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
        </svg>
      ),
    },
    {
      name: 'Đào tạo',
      link: '#daotao',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
          <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
          <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
        </svg>
      ),
    },
    {
      name: 'Sản phẩm',
      link: '#sanpham',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path d="M19.5 21a3 3 0 003-3v-4.5a3 3 0 00-3-3h-15a3 3 0 00-3 3V18a3 3 0 003 3h15zM1.5 10.146V6a3 3 0 013-3h5.379a2.25 2.25 0 011.59.659l2.122 2.121c.14.141.331.22.53.22H19.5a3 3 0 013 3v1.146A4.483 4.483 0 0019.5 9h-15a4.483 4.483 0 00-3 1.146z" />
        </svg>
      ),
    },
    {
      name: 'Tin tức',
      link: '#tintuc',
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path
            fillRule="evenodd"
            d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 003 3h15a3 3 0 01-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125zM12 9.75a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H12zm-.75-2.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM6 12.75a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5H6zm-.75 3.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM6 6.75a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-3A.75.75 0 009 6.75H6z"
            clipRule="evenodd"
          />
          <path d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 01-3 0V6.75z" />
        </svg>
      ),
    },
  ];

  //Logos
  const logos = {
    aptech: { img: aptechLogo, link: 'https://aptech.cusc.vn/' },
    arena: { img: arenaLogo, link: 'https://arenacantho.cusc.vn/' },
    cusc: { img: cuscLogo, link: 'https://aptechcantho.cusc.vn/' },
    dhct: { img: dhctLogo, link: 'https://www.ctu.edu.vn/' },
  };

  // Mobile header navbar

  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 845);

  const isMobileController = () => {
    setIsMobile(window.innerWidth < 845);
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', isMobileController);
    return () => {
      window.removeEventListener('resize', isMobileController);
    };
  }, []);

  //Minial Header
  const [minimal, setMinimal] = useState(false);

  const minimalControler = () => {
    if (isMobile) {
      setMinimal(window.scrollY > 80);
    } else {
      setMinimal(window.scrollY > window.innerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', minimalControler);
    return () => {
      window.removeEventListener('scroll', minimalControler);
    };
  }, [isMobile]);

  return (
    <header
      className={classNames('z-30 w-full', minimal ? 'fixed  bg-white max-w-screen-2xl' : 'relative bg-transparent')}
    >
      <div className="w-full">
        {/* main section */}
        <div className={classNames('header-section md:px-5', !isMobile && minimal && 'lg:max-w-[85%] mx-auto')}>
          {/* logo section on minimal state */}

          {!isMobile && minimal && (
            <div className="grow flex justify-center items-center">
              <div className="grow">
                <a href={logos.dhct.link} target="_blank" rel="noreferrer">
                  <img className="h-auto max-h-8" alt="logo" src={logos.dhct.img} />
                </a>
              </div>
              <div className="grow">
                <a href={logos.cusc.link} target="_blank" rel="noreferrer">
                  <img className="h-auto max-h-8" alt="logo" src={logos.cusc.img} />
                </a>
              </div>
              <div className="grow">
                <a href={logos.aptech.link} target="_blank" rel="noreferrer">
                  <img className="h-auto max-h-8" alt="logo" src={logos.aptech.img} />
                </a>
              </div>
            </div>
          )}
          {/* logo  section*/}
          <div
            className={classNames(
              `logo-section-wrapper mt-5 grow-[2] 
              pb-5 flex justify-items-start 
              items-center sm:w-full md:w-auto  
              sm:justify-center md:static md:justify-between z-[52]`,
              !isMobile && minimal && 'hidden',
              isMobile && open && 'invisible',
            )}
          >
            <div className="mx-2 grow-0 md:grow">
              <a href={logos.dhct.link} target="_blank" rel="noreferrer">
                <img alt="logo" className="logo-section-img" src={logos.dhct.img} />
              </a>
            </div>
            <div className="mx-2 grow-0 md:grow">
              <a href={logos.cusc.link} target="_blank" rel="noreferrer">
                <img alt="logo" className="logo-section-img" src={logos.cusc.img} />
              </a>
            </div>
            <div className="mx-2 grow-0 md:grow md:hidden">
              <a href={logos.arena.link} target="_blank" rel="noreferrer">
                <img alt="logo" className="logo-section-img" src={logos.arena.img} />
              </a>
            </div>
            <div className="mx-2 grow-0 md:grow">
              <a href={logos.aptech.link} target="_blank" rel="noreferrer">
                <img alt="logo" className="logo-section-img" src={logos.aptech.img} />
              </a>
            </div>
          </div>
          {/* Website name section on minimal state */}
          {!isMobile && minimal && (
            <div className="grow websitename-section-minimal">
              <div className="mx-auto w-fit flex justify-center rounded-lg px-10 py-2  bg-[yellow]">
                {/* Arena logo */}
                <div className="grow min-w-fit ">
                  <a href={logos.arena.link} target="_blank" rel="noreferrer">
                    <img className="h-8 w-auto mx-auto" alt="logo" src={logos.arena.img} />
                  </a>
                </div>
                {/* Text */}
                <div className="grow-[4] text-[0.6rem] leading-3 text-center uppercase">
                  <span className=" text-[#00275a] w-fit">Trung tâm công nghệ phần mềm đại học cần thơ</span>
                  <br />
                  <span className=" text-[#00275a] font-bold w-fit">
                    Trung tâm đào tạo mỹ thuật đa phương tiện quốc tế
                  </span>
                  <br />
                  <span className="font-bold text-red-600 w-fit">Arena multimedia</span>
                </div>
              </div>
            </div>
          )}
          <div className={classNames('grow-[3] relative', minimal ? 'hidden' : 'md:block')}>
            {/* Website name section */}
            <div className={classNames('websitename-section hidden md:block')}>
              <div className="outside-custom-border">
                <div className="custom-border">
                  {/* Arena logo */}
                  <div className="grow min-w-fit  ">
                    <a href={logos.arena.link} target="_blank" rel="noreferrer">
                      <img className="h-6 md:h-10 w-auto lg:h-14 float-right" alt="logo" src={logos.arena.img} />
                    </a>
                  </div>
                  {/* Text */}
                  <div className="websitename-text-wrapper">
                    <span className=" text-[#00275a] w-fit">Trung tâm công nghệ phần mềm đại học cần thơ</span>
                    <br />
                    <span className=" text-[#00275a] font-bold w-fit">
                      Trung tâm đào tạo mỹ thuật đa phương tiện quốc tế
                    </span>
                    <br />
                    <span className="font-bold text-red-600 w-fit">Arena multimedia</span>
                  </div>
                </div>
              </div>
            </div>
            {/* Block behind of website name section */}
            <div className={classNames('behind-block ', isMobile || minimal ? 'hidden' : '')}></div>
          </div>

          {/* Buger Icon for mobile menu toggle */}
          <div className="grow-[1]  top-[0] right-0 z-[999] md:hidden fixed">
            <div id="menuToggle" className="my-auto -mt-2 float-right pr-3 sm:pr-5">
              <input
                type="checkbox"
                checked={open}
                onChange={() => {
                  setOpen(!open);
                }}
              />
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          {/* Search box*/}
          <div className={classNames('searchbox-section grow-[2]', minimal ? 'hidden' : 'hidden md:block')}>
            <div className="searchbox-wrapper ">
              <input className="searchbox-inp" placeholder="Tìm kiếm thông tin" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-11 w-11 cursor-pointer -translate-y-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>

          {/* Register button on minimal state */}
          <div className={classNames(!isMobile && minimal ? 'grow flex items-center justify-center' : 'hidden')}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLScfscLTsI6ZxA7hHuOEgMjUOl5eo9eOXsbzAD6TSRauhdhBwA/viewform"
              className="header-register-btn"
            >
              Đăng ký
            </a>
          </div>
        </div>

        {/* navbar section */}
        <div className={classNames('w-full', minimal && !isMobile ? 'navbar-wrapper-minimal' : '')}>
          <ul
            className={classNames(
              isMobile ? 'menu' : 'transform-none',
              !isMobile && minimal ? 'navbar-list-minimal ' : '',
              !isMobile && !minimal && 'md-menu mt-6 md:mt-6',
              isMobile && open ? 'transform-none' : '-translate-x-[120%] w-10rem',
            )}
          >
            {/* Mobile display logos */}
            {isMobile && (
              <>
                <div className=" flex justify-items-start h-20 px-5">
                  <div className="mx-2 grow-0 ">
                    <a href={logos.dhct.link} target="_blank" rel="noreferrer">
                      <img alt="logo" className=" logo-section-img" src={logos.dhct.img} />
                    </a>
                  </div>
                  <div className="mx-2 grow-0">
                    <a href={logos.cusc.link} target="_blank" rel="noreferrer">
                      <img alt="logo" className=" logo-section-img" src={logos.cusc.img} />
                    </a>
                  </div>
                  <div className="mx-2 grow-0  md:hidden">
                    <a href={logos.arena.link} target="_blank" rel="noreferrer">
                      <img alt="logo" className=" logo-section-img" src={logos.arena.img} />
                    </a>
                  </div>
                  <div className="mx-2 grow-0 ">
                    <a href={logos.aptech.link} target="_blank" rel="noreferrer">
                      <img alt="logo" className=" logo-section-img" src={logos.aptech.img} />
                    </a>
                  </div>
                </div>
                <hr className="menu-separator" />
              </>
            )}

            {/* Menu items */}
            {menuItems.map((item, index) => (
              <li
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveMenuItem(index);
                  scrollToSection(item.link);
                }}
              >
                <a href={`${item.link}`}>
                  <div
                    className={classNames(
                      isMobile && index === activeMenuItem && 'menu-item-active', //mobile
                      !isMobile &&
                        minimal &&
                        (index === activeMenuItem ? 'minimal-menu-item-active' : 'minimal-menu-item'), //minimal
                      !isMobile &&
                        !minimal &&
                        (index === activeMenuItem ? 'md-menu-item-active text-center' : 'md-menu-item text-center'), //normal
                    )}
                  >
                    <span className={classNames(!isMobile && 'justify-center', 'flex')}>
                      {isMobile && <span className="mr-5">{item.icon}</span>}
                      {item.name}
                    </span>
                  </div>
                </a>
              </li>
            ))}

            {/* Minimal display searchbox */}
            {!isMobile && minimal && (
              <div className="searchbox-wrapper-minimal">
                <input className="searchbox-input-minimal" placeholder="Tìm kiếm thông tin" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 cursor-pointer -translate-y-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </ul>
        </div>
        {/* Overlay */}
        {isMobile && open && <div className="header-overlay"></div>}
      </div>
    </header>
  );
};

export default Header;
