import './App.css';


import Header from './components/Header';
import Banner from './components/Banner';
import Slide from './components/Slide';
import CallToAction from './components/CallToAction';
import Curriculum from './components/Curriculum';
import GoodStudentExamples from './components/GoodStudentExamples';
import Instructors from './components/Instructors';
//import News from './components/News';
import Footer from './components/Footer';
import WhyArena from './components/WhyArena';
import AdmissionInfo from './components/AdmissionInfo';
import Achieverments from './components/Achievement2/';
//import Products from './components/Products/';
import BackToTop from './components/BackToTop';

function App() {
  return (
    <>
      <div className="max-w-screen-2xl mx-auto min-w-[375px]">
        <Header />
        <BackToTop />
        {/* Wrap components in sections with id for scrollpy  */}
        <section id="banner" data-scroll="banner">
          <Banner />
        </section>
        <section id="gioithieu" data-scroll="gioithieu">
          <Slide />
        </section>
        <section id="ghidanh" data-scroll="ghidanh">
          <CallToAction />
        </section>
        <section id="whilearena" data-scroll="whilearena">
          <WhyArena />
        </section>
        <section id="achieverments" data-scroll="achieverments">
          <Achieverments />
        </section>
        <section id="instructors" data-scroll="instructors">
          <Instructors />
        </section>
        <section id="admissionInfo" data-scroll="admissionInfo">
          <AdmissionInfo />
        </section>
        <section id="goodstudent" data-scroll="goodstudent">
          <GoodStudentExamples />
        </section>
        <section id="daotao" data-scroll="daotao">
          <Curriculum />
        </section>
        {/* <section id="sanpham" data-scroll="sanpham">
          <Products />
        </section> */}
        <section id="footer" data-scroll="footer">
          <Footer />
        </section>
      </div>
    </>
  );
}

export default App;
