import { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';

import facebook from '../../assets/images/Footer/icon/facebook.png';
import zalo from '../../assets/images/Footer/icon/zalo.png';
import youtube from '../../assets/images/Footer/icon/youtube.jpg';
import instagram from '../../assets/images/Footer/icon/instagram.png';
import QrCodeZalopage from '../../assets/images/Footer/Qr-code-Zalopage-Aptech.jpg';

import './Footer.css';
import Title from '../shared/Title';
function Footer() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMap, setModalMap] = useState(false);
  const [year, setYear] = useState(false);

  useEffect(() => {
    setYear(new Date().getFullYear());
  }, []);

  const contentStyleZalo = {
    maxWidth: '1024px',
    width: 'fit-content',
    height: '95vh',
  };

  const contentStyleMap = {
    maxWidth: '1024px',
    minWidth: '300px',
    width: 'fit-content',
    height: 'fit-content',
  };

  return (
    <>
      <div className="relative bg-cover w-full h-[1000px] md:h-[1100px] 2xl:h-[1250px] footer__background">
        <div className="w-full absolute bottom-0">
          <div className="mt-5">
            <div className="flex justify-center">
              <p className="footer__icon">
                <a href="https://www.facebook.com/CUSC.CE/" target="_blank">
                  <img src={facebook} />
                </a>
              </p>
              <p className="footer__icon">
                <a
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  target="_blank"
                >
                  <img src={zalo} />
                </a>
              </p>
              <div className="footer__icon">
                <a href="https://www.youtube.com/c/CUSCAPTECHCHANNEL" target="_blank">
                  <img src={youtube} />
                </a>
              </div>
              <div className="footer__icon">
                <a href="https://www.instagram.com/arena.cantho/" target="_blank">
                  <img src={instagram} />
                </a>
              </div>
            </div>
          </div>
          <div className="mt-5 px-10 md:px-5">
            <Title className="footer-text text-center font-bold uppercase mb-8 mt-10">
              <p>TRUNG TÂM CÔNG NGHỆ PHẦN MỀM</p>
              <p>&nbsp;ĐẠI HỌC CẦN THƠ</p>
            </Title>
            <a
              onClick={() => {
                setModalMap(true);
              }}
              className="footer-text text-center font-bold text-base my-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-2 inline-block -translate-y-1"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              <p>Khu III,</p>
              <p>&nbsp;ĐH Cần Thơ,</p>
              <p>&nbsp;01 Lý Tự Trọng,</p>
              <p>&nbsp;Q. Ninh Kiều, TP. Cần Thơ</p>
            </a>

            <div className="flex justify-center text-center font-bold text-base my-2">
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              Email:
              <a className="ml-1" href="mailto:tuyensinh@cusc.ctu.edu.vn?subject=Tuyển sinh">
                tuyensinh@cusc.ctu.edu.vn
              </a>
            </div>

            <div className="flex justify-center text-center font-bold text-base my-2">
              <div className="mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
              Điện thoại:
              <a className="ml-1" href="tel:+842923835581">
                0292 383 5581
              </a>
            </div>
          </div>
          <div className="mt-5 flex p-2 bg-black text-white">
            <p className="uppercase m-auto">ARENA MULTIMEDIA - CUSC © {year}</p>
          </div>
        </div>
      </div>

      <Popup contentStyle={contentStyleZalo} open={modalOpen} closeOnDocumentClick onClose={() => setModalOpen(false)}>
        <div className="modal bg-white h-full relative shadow-2xl rounded-[25px]">
          {/* Title */}
          <div className="p-4 text-sm md:text-lg font-bold uppercase text-center shadow-[0px_0px_12px_rgba(0,0,0,0.1)] rounded-[25px] flex flex-row-reverse">
            <span
              className="close text-4xl hover:scale-[1.1] cursor-pointer text-red-600 float-right -translate-y-1 font-bold right-0 mx-2"
              onClick={() => setModalOpen(false)}
            >
              &times;
            </span>
            <span class="text-center grow"> Zalo offical</span>
          </div>
          {/* Content */}
          <div className="content h-full pb-8 px-2.5 relative">
            <div className="mt-4  md:mx-10 overflow-auto no-scrollbar h-full relative">
              <img className="relative h-[80%] left-[50%] translate-x-[-50%]" src={QrCodeZalopage} />
            </div>
          </div>
        </div>
      </Popup>

      <Popup contentStyle={contentStyleMap} open={modalMap} closeOnDocumentClick onClose={() => setModalMap(false)}>
        <div className="modal bg-white h-full relative shadow-2xl rounded-[25px]">
          {/* Title */}
          <div className="p-4 text-sm md:text-lg font-bold uppercase text-center shadow-[0px_0px_12px_rgba(0,0,0,0.1)] rounded-[25px] flex flex-row-reverse">
            <span
              className="close text-4xl hover:scale-[1.1] cursor-pointer text-red-600 float-right -translate-y-1 font-bold right-0 mx-2"
              onClick={() => setModalMap(false)}
            >
              &times;
            </span>
            <span class="text-center grow">Khu III, ĐH Cần Thơ, 01 Lý Tự Trọng, Q. Ninh Kiều, TP. Cần Thơ</span>
          </div>
          {/* Content */}
          <div className="content h-full pb-8 px-2.5 relative">
            <div className="mt-4  md:mx-10 overflow-auto no-scrollbar h-full relative">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.793338384504!2d105.77768491428219!3d10.033905575203882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a0881f9a732075%3A0xfa43fbeb2b00ca73!2sCUSC%20-%20Cantho%20University%20Software%20Center!5e0!3m2!1svi!2sus!4v1663337506772!5m2!1svi!2sus"
                width="600"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}

export default Footer;
