import axiosClient from './axiosClient';
var sheetName = 'giang_vien';

const instructorsAPI = {
  getAll: async () => {
    const url = '/' + sheetName;
    const instructorList=[];
    await axiosClient.get(url).then(res=>{
        res.map(ins=>{instructorList.push(
            {
                title:ins.tieu_de,
                description:ins.mo_ta,
                name:ins.ho_ten,
                image:ins.hinh_anh
            }
        )});
    });
    return instructorList;
  },
};

export default instructorsAPI;