import { useEffect, useState } from 'react';
import './achievement.css';
import Title from '../../components/shared/Title';
import { apispreadsheets, imgFromDriveUrl } from '../shared/ApiSpreadSheets';

function Achievements() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [sheet, setSheet] = useState([]);
  const [productContent, setProductContent] = useState([]);
  // FETCH DATA AND SET STATUS
  useEffect(() => {
    const sheetName = 'nhung_diem_noi_bat_cua_arena';
    fetch(`${apispreadsheets}/${sheetName}`)
      .then((res) => res.json())
      .then(
        (results) => {
          setSheet(results);
          const productArr = results.slice(1);
          setProductContent(productArr);
          setIsLoaded(true);
        },
        (error) => {
          console.log('error: ', error);
          setIsLoaded(false);
        },
      );
  }, []);

  return (
    <>
      <div className="achievement px-5 xl:px-36 mb-20">
        <Title className="text-center text-primary-color my-10">những điểm nổi bật của arena multimedia</Title>
        <div className="achievement-content items-stretch grid gap-x-5 gap-y-10 grid-cols-2 md:gap-0 md:flex md:border-b-4 md:border-b-primary-color">
          <div className="brand-img items-center w-full flex justify-center md:w-[20%] col-span-2">
            {isLoaded && <img className="w-[40%] md:w-full" src={imgFromDriveUrl(sheet[0].hinh_anh)} alt="brand-img" />}
          </div>
          {productContent.map((val, index) => (
            <div key={'highlight ' + index} className="achievement-box mx-auto w-full sm:mx-0 md:w-[20%] md:pl-5">
              <div className="achievement-box-container flex items-center flex-col relative h-full">
                <div className="header flex justify-center relative w-full h-16">
                  {isLoaded && (
                    <img
                      className="header-image absolute w-20 h-20 sm:h-28 sm:w-28"
                      src={imgFromDriveUrl(val.hinh_anh)}
                      alt="highlight-img"
                    />
                  )}
                </div>
                <div className="content flex justify-center items-center flex-col w-full h-full sm:h-[73%] border-2 border-[#fff212] rounded-[10px] pt-8 sm:pt-16 pb-5 px-3.5 shadow-[0_0_10px_0px_#adb5bd]">
                  <div className="number text-3xl  font-bold uppercase text-primary-color">
                    {((isLoaded && val.chu_so) || 0).toLocaleString('de-DE', { minimumFractionDigits: 0 })}
                  </div>
                  <div className="titile text-base md:text-xl font-bold uppercase text-center">
                    {isLoaded && val.tieu_de}
                  </div>
                </div>
                {/* footer */}
                <div className="footer md:flex md:justify-center h-[100px] relative hidden">
                  <div className="circle-top w-[40px] h-[40px] border-[3px] border-primary-color rounded-full bg-white translate-y-[-50%] absolute"></div>
                  <div className="center-line h-full w-[3px] bg-primary-color mt-px"></div>
                  <div className="circle-bottom absolute translate-x-[0] translate-y-[263%] w-[25px] h-[25px] border-[3px] border-primary-color rounded-full bg-white top-[1.5%] 2xl:-top-[1.6px]"></div>
                  <div className="circle-bottom-outline absolute translate-x-[0] translate-y-[263%] w-[25px] h-[25px] border-[3px] border-primary-color rounded-full bg-white top-[1.5%] 2xl:-top-[1.6px]"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default Achievements;
